import React from "react";
import Card from 'react-bootstrap/Card';

function Contact() {
    document.title = "CIVA Lab Contact";
    return (
        <div>
            <Card style={{width: '28%', float: 'left'}}>
                <Card.Body>
                    <Card.Text>
                        <h4>Address</h4>
                        <p>205 Naka Hall <br/> University of Missouri <br/> Columbia, MO <br/> 65211-2060</p>

                        <h4>Phone</h4>
                        <p>(573) 884-9266</p>

                        <br/><br/>
                        <h4>Specific Members</h4>
                        <a href="/people">People</a>

                    </Card.Text>
                </Card.Body>
            </Card>

            <Card style={{width: '70%', float: 'right', minHeight: '700px', overflow: 'hidden'}}>
                <Card.Body style={{padding: 0}}>
                    <iframe
                        title="Map for EECS Department"
                        style={{width: '100%', height: '100%'}}
                        loading="lazy"
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                        src="https://www.google.com/maps?t=m&q=University+of+Missouri&dg=opt&ie=UTF8&hq=University+of+
                        Missouri&ll=38.947257,-92.331521&spn=0.001877,0.00295&z=18&iwloc=lyrftr:m,5744294899186944622,
                        38.946769,-92.331462&output=embed">
                    </iframe>
                </Card.Body>
            </Card>

        </div>
    );
}

export default Contact;

import {createBrowserRouter} from "react-router-dom";
import Home from "./home/Home";
import People from "./people/People";
import Publications from "./publications/Publications";
import Resources from "./resources/Resources";
import Contact from "./contact/Contact";
import Error404 from "./error/Error404";
import React from "react";


/*
The primary router for the site. It's fairly rudimentary, matching the simple website architecture.
 */
export const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>
    },
    {
        path: "/home",
        element: <Home/>
    },
    {
        path: "/people",
        element: <People/>
    },
    {
        path: "/publications",
        element: <Publications/>
    },
    {
        path: "/resources",
        element: <Resources/>
    },
    {
        path: "/contact",
        element: <Contact/>
    },
    {
        path: "*",
        element: <Error404/>
    },
]);
function Error404() {
    document.title = "Page Not Found";
    return (
        <div style={{textAlign: 'center'}}>
            <header style={{
                minHeight: '80vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 'calc(10px + 2vmin)',
                color: 'black'
            }}>
                <h1>404</h1>
                <p>
                    This page does not exist or cannot be found with the given URL.
                </p>
            </header>
        </div>
    );
}

export default Error404;

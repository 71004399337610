import React from "react";

export default function PersonImage(props) {
    const imageName = props.imageName.split("/")[1];

    return (
        <img loading="lazy" src={"/api/person/" + imageName} alt={imageName}
             style={{width: "150px", height: "200px", objectFit: "cover"}}/>
    );
}

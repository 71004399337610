import React from "react";
import Card from "react-bootstrap/Card";
import PersonImage from "./PersonImage";

export default function PersonIcon(props) {
    const person = props.person;
    return (
        <Card style={{overflow: 'hidden', marginBottom: '0px', width: "150px", height:"265px"}}>
            <Card.Body style={{padding: 0}}>
                <div style={{
                    margin: '0px', color: 'rgb(200, 200, 200)',
                    paddingRight: '0px'
                }}>
                    <PersonImage imageName={person.image} larger/>
                </div>
                <div style={{padding: '0px', margin:"4px"}}>
                    <Card.Title style={{textAlign: "center"}}>{person.name}</Card.Title>
                </div>
            </Card.Body>
        </Card>
    );
}

import {Col, Row} from "react-bootstrap";
import HorizontalLine from "../common/HorizontalLine";
import PersonCard from "./PersonCard";
import React from "react";
import PersonIcon from "./PersonIcon";

export default function PeopleSection(props) {
    return (
        <div>
            {props.compact ?
                //The compact version of a people array for collaborators and alum which don't need a bio displayed
                (<Row className="g-4">
                    {props.array.map((person) => (
                        <Col style={{flex:"0 0"}}>
                            <PersonIcon person={person}/>
                        </Col>
                    ))}
                </Row>)
                :
                props.wide ?
                //The wide version of a people array (used for professors with extensive bios)
                (props.array.map((person) => (
                    <div style={{marginBottom: '24px'}}>
                        <PersonCard person={person}/>
                    </div>
                ))) :
                //The 2-column grid array of people used for every other category
                (<Row xs={1} md={2} className="g-4">
                    {props.array.map((person) => (
                        <Col>
                            <PersonCard person={person}/>
                        </Col>
                    ))}
                </Row>)
            }
            <div style={{margin: '20px'}}>
                <HorizontalLine/>
            </div>
        </div>
    );
}

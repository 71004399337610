

function HorizontalLine() {
    return(
        <hr
            style={{
                padding: 0,
                margin: 0,
                marginTop: 5,
                marginBottom: 10,
                color: "black",
                backgroundColor: "black",
                height: 1
            }}
        />
    );
}

export default HorizontalLine;
import Card from "react-bootstrap/Card";
import HorizontalLine from "../common/HorizontalLine";
import {Tab, Tabs} from "react-bootstrap";
import React from "react";
import PublicationImage from "./PublicationImage";

export default function PublicationCard(props) {
    const item = props.item;
    return (
        <Card style={{marginBottom: '15px', overflow: 'hidden', backgroundColor: "rgba(240, 240, 240)"}}>
            <Card.Body style={{padding: 0}}>
                {item.paper_number && item.paper_number.split("/")[1] && <div style={{
                    float: 'right', margin: '10px', color: 'rgb(200, 200, 200)', paddingLeft: '5px'
                }}>
                    <PublicationImage imageName={item.paper_number}/>
                </div>}

                <div style={{padding: "15px"}}>
                    <Card.Title>{item.title}</Card.Title>
                    <HorizontalLine/>
                    <Card.Text style={{marginBottom: '8px'}}><i>{"#" + item.new_id}</i>{": " + item.authors}</Card.Text>

                    <Card.Text>{
                        (item.journal ? item.journal : item.book_title) +
                        (item.pages ? ", pages " + item.pages : "") +
                        ", " + item.year
                    }</Card.Text>

                    {item.keywords[0] ? (<Card.Text style={{color: 'rgb(120, 120, 120)', fontSize: 12}}>
                        Keywords:
                        {" " + item.keywords.map((keyword) => (
                            " " + keyword
                        ))}
                    </Card.Text>) :
                        (<Card.Text style={{color: 'rgb(120, 120, 120)', fontSize: 12}}>
                            Keywords: N/A
                        </Card.Text>)
                    }

                    <div style={item.paper_number.split("/")[1] ? {clear: "both"} : {}}>

                        <Tabs defaultActiveKey="external" id="uncontrolled-tab-example" className="mb-3" style={{"--bs-nav-tabs-border-color": "rgba(255, 255, 255)"}}>
                            <Tab eventKey="cite" title="Cite">
                                <Card style={{float: "left", width: "49%", marginBottom: 15}}>
                                    <Card.Body>
                                        <Card.Title>BibTex</Card.Title>
                                        <Card.Text>
                                            {item['bibtex']}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <Card style={{float: "right", width: "49%", marginBottom: 15}}>
                                    <Card.Body>
                                        <Card.Title>Plain Text</Card.Title>
                                        <Card.Text>
                                            {item['plaintext']}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Tab>

                            <Tab eventKey="abstract" title="Abstract">
                                <Card style={{float: "left", width: "100%", marginBottom: 15}}>
                                    <Card.Body>
                                        <Card.Text>
                                            {item.abstract}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Tab>

                            <Tab eventKey="download" title="Download" disabled={!item.pdf}>
                                {item.pdf && (
                                    <div>
                                        <a href={"/api/media/" + item.pdf.split("/")[1]}>
                                            PDF</a>
                                        &nbsp;&nbsp;
                                    </div>
                                )}
                            </Tab>

                            <Tab eventKey="external" title="External Links">
                                {item.doi ? <a href={"https://doi.org/" + item.doi}>DOI</a> :
                                    "DOI"}
                                &nbsp;&nbsp;
                                {item.url ? <a href={item.url}>URL</a> :
                                    "URL"}
                                &nbsp;&nbsp;
                                <a href={"http://scholar.google.com/scholar?q=" + item.title + " author:'" +
                                    item.authors.split(",")[0] + "'"}>Google Scholar</a>
                                &nbsp;&nbsp;
                                {
                                    //Not many publications have source code, so because this is the last item
                                    //in the list, it's acceptable to leave it out completely because it doesn't
                                    //change alignment of other links. It's left out to not draw attention
                                    //to the lack of source code for so many publications
                                    item.code && <a href={item.code}>Source Code</a>
                                }
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import Container from 'react-bootstrap/Container';
import './index.css';
import {RouterProvider} from "react-router-dom";
import MainNav from "./common/MainNav";

import 'bootstrap/dist/css/bootstrap.css';
import {router} from "./Router";


/*
The overall structure of the site is as follows:
The nav bar is rendered regardless of the page (as seen below).
The rest of the content is decided on by the router (also seen below) and encapsulated by a container which standardizes
the content area size across pages.

It is the responsibility of each page to set their page title. This is often done in the top level component of the
page i.e. Home, People, etc. However, a subcomponent can set it if necessary, as is the case with WIP pages
displaying the UnderDev component.
 */

ReactDOM.createRoot(document.getElementById("root")).render(
    <div>
        <MainNav/>
        <Container>
            <div style={{padding: '20px'}}>
                <RouterProvider router={router}/>
            </div>
        </Container>
    </div>
);
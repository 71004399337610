import React from "react";

export default function PublicationImage(props) {
    const imageName = props.imageName.split("/")[1];

    return (
        <img loading="lazy" src={"/api/media/" + imageName} alt={imageName}
             style={{width: "300px", height: "225px", objectFit: "cover", border: "1px", borderColor: "rgba(0, 0, 0, 0.175)", borderStyle: "solid"}}/>
    );
}

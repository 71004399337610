import "./People.css"
import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, Spinner } from "react-bootstrap";
import PeopleSection from "./PeopleSection";


function People() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    //Process to load the list from the backend
    useEffect(() => {
        fetch('/api/people')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Could not fetch people list. Status: ' + response.status);
                }
                return response.json();
            }).then((jsonData) => {
            setData(jsonData);
            setError(null);
        }).catch((err) => {
            setError(err.message);
            setData(null);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    document.title = "CIVA Lab People";
    return (
        <div>
            {loading &&
            <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 50}}>
                <Spinner animation="border" role="status"/>
            </div>
            }
            {error && <h3>Error loading people: {error}</h3>}

            {data && (
                <div>
                    <ButtonGroup style={{paddingBottom: '20px'}}>
                        <Button variant="light" href="#faculty">Faculty</Button>
                        <Button variant="light" href="#postdoc">Postdoc</Button>
                        <Button variant="light" href="#grad">Graduate</Button>
                        <Button variant="light" href="#undergrad">Undergraduate</Button>
                        <Button variant="light" href="#collab">Collaborators</Button>
                        <Button variant="light" href="#postdocalum">Postdoc Alumni</Button>
                        <Button variant="light" href="#gradalum">Graduate Alumni</Button>
                    </ButtonGroup>

                    <h3 id="faculty">Faculty</h3>
                    <PeopleSection array={data.faculty} wide />

                    <h3 id="postdoc">Postdocs</h3>
                    <PeopleSection array={data.postdoc} />

                    <h3 id="grad">Graduate</h3>
                    <PeopleSection array={data.grad} />

                    <h3 id="undergrad">Undergraduate</h3>
                    <PeopleSection array={data.undergrad} />

                    <h3 id="collab">Collaborators</h3>
                    <PeopleSection array={data.collab} compact />

                    <h3 id="postdocalum">Postdoc Alumni</h3>
                    <PeopleSection array={data.postdocalum} compact />

                    <h3 id="gradalum">Graduate Alumni</h3>
                    <PeopleSection array={data.gradalum} compact />
                </div>
            )}
        </div>
    );
}

export default People;

import React from "react";
import Card from 'react-bootstrap/Card';

function Home() {
    document.title = "CIVA Lab";
    return (
        <div>
            <Card style={{width: '100%'}}>
                <Card.Img variant="top" src={require('../common/Mizzou.jpg')}/>
                <Card.Body>
                    <Card.Text>
                        The Computational Imaging and Visualization Analysis lab in the
                        &nbsp;
                        <a href="https://engineering.missouri.edu/departments/eecs/">
                            Department of Electrical Engineering and Computer Science
                        </a>
                        &nbsp;
                        at the
                        &nbsp;
                        <a href="https://missouri.edu/">
                            University of Missouri
                        </a>
                        &nbsp;
                        collaboratively develops algorithms, tools, and
                        software for quantifying visual information and facilitating discoveries in STEMM. CIVA
                        conducts research on computational aspects of computer vision, image processing,
                        visualization, algorithm parallelization, high performance computing, machine learning
                        and mathematical methods for image & video understanding. Application areas center
                        around wide area motion imagery and full motion video for defense biometrics. In the
                        biological and biomedical areas we are focused on multiscale systems biology and
                        phenomics from sub-cellular to tissue scale analysis for understanding function and
                        disease processes. Our research has been funded by DoD, NIH, NSF, NASA and NGA.
                    </Card.Text>
                </Card.Body>
            </Card>


        </div>
    );
}

export default Home;

import './MainNav.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function MainNav() {
    return (
        <Navbar bg="light" variant="light" expand="md" class="main-nav" sticky="top">
            <Container>
                <Navbar.Brand href="/home">COMPUTATIONAL IMAGING & VISANALYSIS LAB</Navbar.Brand>
                    <Nav className="pull-right">
                        <Nav.Link href="/people">PEOPLE</Nav.Link>
                        <Nav.Link href="/publications">PUBLICATIONS</Nav.Link>
                        <Nav.Link href="/resources">RESOURCES</Nav.Link>
                        <Nav.Link href="/contact">CONTACT</Nav.Link>
                    </Nav>
            </Container>
        </Navbar>
    );
}

export default MainNav;

import React, {useEffect, useState} from "react";
import {Dropdown, DropdownButton, Spinner} from "react-bootstrap";
import {useSearchParams} from "react-router-dom";

import PublicationCard from "./PublicationCard";
import "./Publications.css";

//This approach is inefficient, but it's JavaScript so who cares it's not going to be fast anyway
function filterByKeywords(publications, queryKeywords) {
    let years = {};
    publications.forEach((year) => {
        if (!years[year['year']])
            years[year['year']] = {"year": year['year'], "pubs": []};

        year['pubs'].forEach((pub) => {
            let keywordMatchFound = false;
            pub.keywords.forEach((keyword) => {
                //Only continue searching for keyword matches if one hasn't been found
                //Checking this every time is inefficient but probably less inefficient than always searching
                if (!keywordMatchFound)
                    queryKeywords.forEach((queryKeyword) => {
                        if (queryKeyword === keyword)
                            keywordMatchFound = true;
                    })
            })

            if (keywordMatchFound)
                years[pub.year]['pubs'].push(pub);
        });
    });

    //Then convert to an array of dictionaries
    let yearsArray = [];
    Object.entries(years).forEach((yearDict) => {
        if (yearDict[1]['pubs'].length !== 0)
            yearsArray.push(yearDict[1]);
    })

    //Sort by year
    yearsArray.sort((a, b) => (a.year < b.year ? 1 : -1));

    return yearsArray;
}

function buildKeywordsList(publications) {
    let words = {};
    publications.forEach((year) => {
        year['pubs'].forEach((pub) => {
            pub.keywords.forEach((keyword) => {
                if (keyword !== "") {
                    if (!words[keyword])
                        words[keyword] = 0;

                    words[keyword]++;
                }
            })
        });
    });

    let wordsCountArray = [];
    Object.entries(words).forEach(([key, count]) => {
        if (count > 5)
            wordsCountArray.push({keyword: key, count: count});
    })

    wordsCountArray.sort((a, b) => (a.keyword.localeCompare(b.keyword)));

    return wordsCountArray;
}

function Publications() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    //Process to load the list from the backend
    useEffect(() => {
        fetch('/api/publications')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Could not fetch publications. Status: ' + response.status);
                }
                return response.json();
            }).then((jsonData) => {
            setData(jsonData);
            setError(null);
        }).catch((err) => {
            setError(err.message);
            setData(null);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    const changeKeywordSelection = (eventKey, event) => {
        let paramsStr = searchParams.get("keywords");
        if(typeof paramsStr !== typeof "") {
            setSearchParams({"keywords": ""});
            paramsStr = ""
        }

        //String split returns an array with one empty string if the original string is empty.
        //Fix that behavior.
        let params = paramsStr === "" ? [] : paramsStr.split(",");

        if(!params.includes(eventKey))
            params.push(eventKey);
        else
            params.splice(params.indexOf(eventKey), 1); //Remove any keyword filtering if the active keyword is clicked again

        setSearchParams({"keywords": params.join(",")});
    }

    document.title = "CIVA Lab Publications";
    return (
        <div>
            {loading &&
                <div style={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: 50}}>
                    <Spinner animation="border" role="status"/>
                </div>
            }
            {error && <h3>Error loading publications: {error}</h3>}


            {data && (
                <div>
                    <DropdownButton title="Filter Keywords" variant="light" style={{paddingBottom: 25}} onSelect={changeKeywordSelection}>
                        {buildKeywordsList(data).map((keywordDict) => (
                            <Dropdown.Item eventKey={keywordDict.keyword} active={searchParams.get("keywords") && searchParams.get("keywords").split(",").includes(keywordDict.keyword)}>
                                {keywordDict.keyword + ": " + keywordDict.count}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                    {
                        (searchParams.get("keywords") ? filterByKeywords(data, searchParams.get("keywords").split(",")) : data)
                            .map((yearGroup) => (
                        <div style={{marginBottom: 40}} id={yearGroup['year']}>
                            <h2>{yearGroup['year']}</h2>

                            {yearGroup['pubs'].map((item) => (
                                <PublicationCard item={item} />
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Publications;
